/* eslint-disable camelcase */
import React from 'react';
import { useTheme } from 'styled-components';

// Types
import { TNewsletterPost } from '../../../utils/types';

// Atoms
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';

// Organisms
import Section from 'fatcat-ui-library/components/Molecules/Section';

const parseTitle = (title: string) => {
	// checks last character in string
	const regex = /[?!.]$/g;
	const haveChar = title.match(regex);
	return (haveChar ? (
		<>
			{title.substring(0, title.length - 1)}
			<Text textColor="green">{title.slice(-1)}</Text>
		</>
	) : title);
};

const Hero: React.FC<TNewsletterPost> = (props) => {
	const theme = useTheme();
	const {
		publish_date,
		title,
		thumbnail_url,
	} = props;

	return (
		<Section
			paddingBottom="s56"
			scale
		>
			<Flex
				alignItems="center"
				gap="130px"
				borderBottom={`1px solid ${theme.color.lines}`}
				padding={['b56']}
				largeTablet={[{ gap: '52px' }]}
				tablet={['column', { gap: '42px' }, { padding: 'b32' }]}
			>
				<Flex direction="column" gap="24px">
					<Heading as="h1">{parseTitle(title)}</Heading>
					<Flex
						gap="16px"
						alignItems="center"
						fontSize="s20"
						textColor="secondary"
						fontWeight="medium"
						mobile={[{ fontSize: 's16' }]}
					>
						<Text>
							{new Date(publish_date * 1000).toLocaleDateString('en-US', {
								weekday: undefined,
								month: 'short',
								day: 'numeric',
								year: 'numeric',
							})}
						</Text>
					</Flex>
				</Flex>
				<Flex
					borderRadius="4px"
					overflow="hidden"
					hide="tablet"
					maxW="40%"
				>
					<Image src={thumbnail_url} />
				</Flex>
			</Flex>
		</Section>
	);
};

export default Hero;

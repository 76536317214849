import React, { useMemo } from 'react';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';
import { css } from 'styled-components';

// Types
import { TNewsletterPost } from '../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading/index';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon/Icon';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';

// Organisms
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Section from 'fatcat-ui-library/components/Molecules/Section';

// Sections
import Hero from '../components/Sections/NewsletterPost/Hero';
import Content from '../components/Sections/NewsletterPost/Content';
import SocialShare from '../components/Molecules/Social/SocialShare';
import NewsletterCard from '../components/Molecules/Cards/NewsletterCard';
import { LuChevronLeft } from 'react-icons/lu';

export type TSingleNewsletterPost = {
	data: {
		post: {
			relatedPosts: TNewsletterPost[]
		} & TNewsletterPost
		allNewsletterPosts: {
			nodes: TNewsletterPost[];
		}
		allFile: {
			nodes: {
				id: string;
				name: string;
				childImageSharp: any;
			}[];
		}
	}
}

const NewsletterPost: React.FC<TSingleNewsletterPost> = (props) => {
	const {
		data: {
			post,
			allNewsletterPosts,
			allFile,
		},
	} = props;

	const data = useMemo(
		() => {
			return {
				allNewsletterPosts: {
					nodes: allNewsletterPosts.nodes.map((np) => {
						return {
							...np,
							thumbnail: allFile.nodes.find(f => f.name === `newsletter-thumbnail-${np.id}`)?.childImageSharp,
						};
					}),
				},
			};
		},
		[allFile, allNewsletterPosts]
	);

	return (
		<main>
			<article>
				<Section
					paddingY="s48"
					scale
				>
					<Link
						as={GatsbyLink}
						to="/newsletter/"
						onClick={() => {
							if (window.prevLocation) {
								navigate(-1);
							}
						}}
						fontWeight="medium"
						fontSize="s16"
						display="flex"
						alignSelf="center"
						mobile={[{ fontSize: 's14' }]}
						styled={css` align-items: center; `}
					>
						<Icon
							as={LuChevronLeft}
							w="16px"
							h="auto"
							margin={['r10', 'b2']}
						/>
						Back to Newsletter
					</Link>
				</Section>
				<Hero {...post} />
				<Section
					paddingBottom="s100"
					scale
				>
					<Flex
						gap="130px"
						position="relative"
						largeTablet={[{ gap: '40px' }]}
						tablet={['column']}
					>
						<Flex
							maxW="140px"
							direction="column"
							gap="20px"
							position="sticky"
							top="140px"
							h="fit-content"
							tablet={['unsetPosition']}
						>
							<SocialShare />
						</Flex>
						<Flex maxW="658px">
							<Content {...post} />
						</Flex>
					</Flex>
				</Section>
				{!!data.allNewsletterPosts.nodes.length && (
					<Section>
						<Heading
							as="h2"
							textAlign="center"
						>
							Related posts:
						</Heading>
						<Flex
							wrap
							gap="20px"
							padding={['t48', 'b80']}
							largeTablet={['justifyContentCenter']}
							tablet={[{ gap: '20px' }]}
						>
							{data.allNewsletterPosts.nodes.map(newsletterPost => (
								<NewsletterCard
									key={newsletterPost.slug}
									{...newsletterPost}
								/>
							))}
						</Flex>
					</Section>
				)}
			</article>
		</main>
	);
};

export default NewsletterPost;

export const Head: React.FC<TSingleNewsletterPost> = (props) => {
	const { data: { post } } = props;
	return (
		<HeadMeta
			meta={{
				pageTitle: post.title,
				ogTitle: post.title,
				metaDescription: '',
				isCanonical: true,
			}}
		/>
	);
};

export const pageQuery = graphql`
    query NewsletterPostPageQuery($slug: String!) {
        post: newsletterPosts(slug: {eq: $slug}) {
			...NewsletterPostFields
        }
		allNewsletterPosts(limit: 2, sort: {order: DESC, fields: publish_date}) {
		 	nodes {
				...NewsletterPostFields
		 	}
         }
		allFile(filter: {name: {regex: "/newsletter-thumbnail/" }}) {
			nodes {
				id
				name
				childImageSharp {
					gatsbyImageData
				}
			}
		}
    }
`;

import React from 'react';

// Types
import { TNewsletterPost } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

import { css } from 'styled-components';

const Content: React.FC<TNewsletterPost> = ({ content: { free: { rss: post } } }) => {
	return (
		<Flex
			styled={css`
    			* {
					font-family: 'Lato' !important;
					line-height: normal;
				}

				iframe {
					aspect-ratio: 16/9;
				}

				p {
					padding: 0.5rem 0 0.5rem 0;
				}

				h3 {
					padding: 1rem 0 1rem 0;
					font-size: 1.2rem;
				}

				.button {
					margin-bottom: 1rem;
					a {
						cursor: pointer;
						display: inline-block;
						font-weight: bold;
						border: 1px solid #1A6B50;
						color: #FFFFFF !important;
						background: #1A6B50;
						border-radius: 100px;
						padding: 16px 32px;
						font-size: 1rem;
						line-height: 1.5;
						font-weight: 600;
						white-space: nowrap;
						text-align: center;

						span {
							color: #FFFFFF !important;
						}
					}
				}

				a {
					color: #1A6B50;
					text-decoration: none;

					span {
						color: #1A6B50 !important;
					}
				}

				b {
					font-weight: bold;
				}

				h1 {
					font-size: 1.5rem;
					font-weight: bold;
					padding: 1rem 0 1rem 0;
				}
				h2 {
					font-size: 1.4rem;
					font-weight: bold;
					padding: 1rem 0 1rem 0;

				}

				.image img {
					max-width: 100%;
				}

				.beehiiv__footer {
					display: none;
				}
	`}
			direction="column"
			dangerouslySetInnerHTML={{ __html: post }}
		/>
	);
};

export default Content;
